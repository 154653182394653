
export const RED = '#e04633'
export const DARK_RED = '#bc2210'
export const ORANGE = '#ff9f00'
export const DARK_ORANGE = '#ce8002'
export const BLUE = '#1876d1'
export const DARK_BLUE = '#0f5596'
export const DISABLED = '#919191'
export const DARK_DISABLED = '#515151'

export const get_pin_color = (hazardous_status:any, dark:boolean=false) => {
	return hazardous_status == 'absent' 
		? (dark ? DARK_BLUE : BLUE) : hazardous_status == 'present' 
			? (dark ? DARK_RED : RED) : hazardous_status == 'disabled' 
				? (dark ? DARK_DISABLED : DISABLED) : (dark ? DARK_ORANGE: ORANGE)
}