import { useState, useEffect, useMemo } from 'react'
import { IonContent, IonPage, useIonToast } from '@ionic/react'
import { useMutation, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import ProgressBar from './ProgressBar'
import Toast from '../utils/Toast'
import { ActionHeader } from './Header'
import './FormFeedback.css'

const SEND_FEEDBACK = gql`
mutation feedback_send($subject: String!, $message: String!, $answer_request: Boolean, $type:SupportMessageEnum) {
	feedback_send(
		subject: $subject 
		message: $message 
		answer_request: $answer_request
		type:$type) {
		message
	}
}`

const FormFeedback = ({ ...props }:any) => {
	const { onConfirm:_onConfirm, mode } = props
	const [sendFeedback, feedbackOp] = useMutation(SEND_FEEDBACK)
	const org_upgrade = mode == 'org_upgrade'

	const [feedback, setFeedback] = useState('')
	const [subject, setSubject] = useState('')
	const [contactMe, setContactMe] = useState(false)
	const [feedbackError, setFeedbackError] = useState(false)
	const ionToast = useIonToast()
	const toast = useMemo(() => new Toast(...ionToast, { duration:4000 }), [ionToast])

	useEffect(() => {
		if (!feedbackOp.loading && feedbackOp.called) {
			if (feedbackOp.error) {
				console.error(feedbackOp.error)
				toast.show('Failed to send feedback. Please contact support for further help.', { error:true, closeText:'close' })
			} else {
				toast.show('Feedback successfully sent')
				if (_onConfirm)
					_onConfirm()
			}
		}
	}, [feedbackOp, toast, _onConfirm])

	const onCancel = props.onCancel || (() => null)
	const onConfirm = async () => {
		let errors = false
		if (!feedback) {
			errors = true
			toast.show('The message is required', { error:true, closeText:'close' })
			setFeedbackError(true)
		}
		
		if (!errors && props.onConfirm) {
			const variables = {
				subject: org_upgrade ? '' : subject,
				message: feedback,
				answer_request: contactMe,
				type: org_upgrade ? 'ORG_UPGRADE' : 'FEEDBACK'
			}
			sendFeedback({ variables })
		}
	}

	const onFeedbackChange = (e:any) => {
		setFeedback(e.target.value)
		if (feedbackError)
			setFeedbackError(false)
	}

	const onSubjectChange = (e:any) => {
		setSubject(e.target.value)
	}

	const onContactMe = (e:any) => {
		setContactMe(e.target.checked)
	}

	return (
	<IonPage {...props}>
		{feedbackOp.loading && <ProgressBar/>}
		<ActionHeader actionName={org_upgrade ? 'Request upgrade' : 'Send'} onCancel={onCancel} onConfirm={onConfirm}/>
		<IonContent className="ion-padding">
			<Box className="feedback-form">
				<Typography sx={{ width:'330px', marginBottom:'10px' }}>
					{org_upgrade ? 'Tell us more about your request:' : 'Enter your message below:'}
				</Typography>{org_upgrade ? null : 
				<TextField 
					label="Subject" 
					disabled={feedbackOp.loading} 
					onChange={onSubjectChange}/>}
				<TextField
					className="feedback-input"
					label="Message content here"
					multiline
					rows={8}
					required
					disabled={feedbackOp.loading}
					error={feedbackError}
					onChange={onFeedbackChange}
				/>{org_upgrade ? null :
				<FormControlLabel control={<Checkbox checked={contactMe} onChange={onContactMe} />} label="Please contact me back" />}
			</Box>
		</IonContent>
	</IonPage>)
}

export default FormFeedback