import Typography from '@mui/material/Typography'
import styled from 'styled-components'

export const CONTAINER_PAD = '7px'
export const GUTTER = '26px'
export const HEADER_ICON_CELL_WIDTH = '70px'

export const is_upgradable = (plan:any) => {
	const p = `${plan}`.toLowerCase().trim()
	return p == 'free' || p == 'professional'
}

export const is_paid = (plan:any) => {
	const p = `${plan}`.toLowerCase().trim()
	return p && p != 'free'
}

export const SectionTitle = ({ ...props }) => {
	const left_gutter = props.left_gutter || GUTTER
	const right_gutter = props.right_gutter || GUTTER
	const padding_bottom = props.padding_bottom || '15px'
	const padding_top = props.padding_bottom || '10px'
	const class_name = props.class_name || ''

	return (<div className={class_name}>
		<Typography 
			variant="h6" 
			component="h2" 
			sx={{ 
				paddingLeft:left_gutter, 
				paddingRight:right_gutter, 
				paddingBottom:padding_bottom, 
				paddingTop:padding_top
			}}>
			{props.children}
		</Typography>
	</div>)
}

const DescriptionBoxDiv = styled.div`
	padding-top:24px;

	& .desc-box {
		border-radius: 12px;
		padding: 12px;
		background-color: var(--bgd-color);

		& .desc-item {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: 45px 1fr;

			& .desc-item-icon {
				& .icon-shape-ring {
					--ring-size:37px;
					--ring-inner-size:calc(0.78 * var(--ring-size));
					--ring-icon-size:calc(0.486 * var(--ring-size));

					border:1.5px solid var(--ring-color);
					border-radius:40px;
					width: var(--ring-size);
					height: var(--ring-size);
					display: flex;
					align-items: center;
					justify-content: center;

					& .icon-shape {
						border:0.5px solid var(--ring-color);
						border-radius:40px;
						background-color:var(--icon-bgd-color);
						width: var(--ring-inner-size);
						height: var(--ring-inner-size);
						display: flex;
						align-items: center;
						justify-content: center;

						& svg {
							font-size:var(--ring-icon-size);
							color:var(--icon-color)
						}
					}
				}
			}

			& .desc-item-details {
				display:flex;
				flex-direction:column;
				padding-bottom:16px;

				& .desc-item-name {
					line-height:1.5;
				}
			}
		}
	}
`

const set_limit_values = (name?:any, description?:any, members?:any, towers?:any, go_weather_credit?:any) => {
	const is_member = name == 'Team members'
	const is_tower = name == 'Tower access'
	const is_credit = name == 'GoWeather credit'
	if ((is_member || is_tower || is_credit) && description) {
		if (is_member)
			return description.replace(/^0\s/, `${members||0} `)
		else if (is_tower)
			return description.replace(/^0\s/, `${towers||0} `)
		else {
			let credit_consumed = 500 - (go_weather_credit||0)
			if (credit_consumed<0)
				credit_consumed = 0
			return description.replace(/^AU\$0\s/, `AU$${credit_consumed||0} `)
		}
	} else
		return description
}

export const DescriptionBox = ({ ...props }) => {
	const { title='Title Here', items, members, towers, go_weather_credit, ...rest } = props
	const { background_color='#f4f4f4', no_icon_color=false } = rest

	const css_vars = {
		'--bgd-color': background_color,
		'--ring-color': no_icon_color ? 'transparent' : '#e0e0e0',
		'--icon-color': 'var(--mu-primary-dark, #1767c1)',
		'--icon-bgd-color': no_icon_color ? 'transparent' : '#e3ebf3'
	} as React.CSSProperties

	return (<div style={css_vars}>
		<DescriptionBoxDiv>
			<Typography variant="h6" component="h2" sx={{ fontSize:'1rem', paddingBottom:'8px' }}>{title}</Typography>
			<div className="desc-box">{(items||[]).map((item:any,key:any) => { return (
				<div className="desc-item" key={key}>
					<div className="desc-item-icon">
						<div className="icon-shape-ring">
							<div className="icon-shape">
								<item.icon/>
							</div>
						</div>
					</div>
					<div className="desc-item-details">
						<Typography variant="h6" component="p" className="desc-item-name" sx={{ fontSize:'1rem', paddingBottom:'0px' }}>{item.name}</Typography>
						<Typography variant="caption" color="text.secondary" className="desc-item-description">{set_limit_values(item.name, item.description, members, towers, go_weather_credit)}</Typography>
					</div>
				</div>)})}
			</div>
		</DescriptionBoxDiv>
	</div>)
}



