// Import the Stripe.js library
import { loadStripe } from '@stripe/stripe-js'

// Your publishable key (replace with your own)
const stripe_promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

// Function to handle the checkout process
export const redirect_to_checkout = async session_id => {
	// Redirect to Stripe Checkout
	const stripe = await stripe_promise
	const { error } = await stripe.redirectToCheckout({ sessionId:session_id })

	if (error) {
		console.error('Error redirecting to Checkout:', error)
	}
}
