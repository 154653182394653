import { delay } from 'puffy-core/time'
import CellTowerIcon from '@mui/icons-material/CellTower'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import AirIcon from '@mui/icons-material/Air'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import InsightsIcon from '@mui/icons-material/Insights'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const local_store = {}

export const store = {
	set: (name, value, set_only_if_undefined) => {
		if (!set_only_if_undefined || local_store[name] === undefined)
			local_store[name] = value
	},
	get: async (name, next) => {
		let previous_value = local_store[name]||null
		if (next) {
			next(previous_value)
			while(true) {
				await delay(1000)
				const new_value = local_store[name]||null
				if (new_value != previous_value) {
					next(new_value, previous_value)
					previous_value = new_value
				}
			}
		}
	}
}

export const get_plan = plant_name => plans[plant_name] || null

const plans = {
	free: {
		name:'free',
		next:'professional',
		price:0,
		upgrade_ad: {
			name:'Level up',
			message: 'Access more towers, collaborate with team members, gain 24 hours forecast and much more.',
			cta:'Get Professional'
		},
		lower_plans:null,
		limits: [{
			icon: <CellTowerIcon/>,
			name:'Tower access',
			description:'3 closest towers only'
		},{
			icon: <AirIcon/>,
			name:'Hazardous forecast',
			description:'Up to 2 hours max.'
		},{
			icon: <CloudQueueIcon/>,
			name:'Weather data history',
			description:'Past 48 hours only'
		},{
			icon: <PeopleAltIcon/>,
			name:'Team members',
			description:'0 available'
		}],
		benefits: [{
			icon: <TipsAndUpdatesIcon/>,
			name:'Weather insights',
			description:'Access the latest basic weather readings for up to 3 towers located around your current GPS location.'
		},{
			icon: <AirIcon/>,
			name:'Hazardous forecast',
			description:'Access the hazardous forecast (up to 2 hours) for up to 3 towers located around your current GPS location.'
		},{
			icon: <InsightsIcon/>,
			name:'Historical charts',
			description:'Monitor the past 48 hours of weather readings with charts'
		}]
	},
	professional: {
		name:'professional',
		next:'organization',
		price:249,
		upgrade_ad: {
			name:'Need even more access?',
			message: 'All that is included in Professional + Unlimited access to all towers and collaboration with up to 20 team members.',
			cta:'Get Organization'
		},
		lower_plans:['free'],
		limits: [{
			icon: <CellTowerIcon/>,
			name:'Tower access',
			description:'0 / 10'
		},{
			icon: <PeopleAltIcon/>,
			name:'Team members',
			description:'0 / 5'
		},{
			icon: <AttachMoneyIcon/>,
			name:'GoWeather credit',
			description:'AU$0 / AU$500'
		}],
		benefits: [{
			icon: <AttachMoneyIcon/>,
			name:'AU$500 GoWeather credit',
			description:'Get a AU$500 on your next GoWeather purchase.'
		},{
			icon: <TipsAndUpdatesIcon/>,
			name:'Weather insights',
			description:'Access the latest weather readings including extra values such as rain for up to 10 towers located anywhere in Australia.'
		},{
			icon: <AirIcon/>,
			name:'Hazardous forecast',
			description:'Access the hazardous forecast for the next 24 hours for up to 10 towers located anywhere in Australia.'
		},{
			icon: <PeopleAltIcon/>,
			name:'Collaboration',
			description:'Invite up to 5 team members to help you take data-driven actions when it matters the most.'
		},{
			icon: <InsightsIcon/>,
			name:'Historical charts',
			description:'Monitor weather readings at any period in the past with charts and data export.'
		}]
	},
	organization: {
		name:'organization',
		next:null,
		price:null,
		upgrade_ad:null,
		lower_plans:['professional', 'free'],
		limits: [{
			icon: <CellTowerIcon/>,
			name:'Tower access',
			description:'0 / Unlimited'
		},{
			icon: <PeopleAltIcon/>,
			name:'Team members',
			description:'0 / 20'
		},{
			icon: <AttachMoneyIcon/>,
			name:'GoWeather credit',
			description:'AU$0 / AU$500'
		}],
		benefits: [{
			icon: <AttachMoneyIcon/>,
			name:'AU$500 GoWeather credit',
			description:'Get a AU$500 on your next GoWeather purchase.'
		}, {
			icon: <TipsAndUpdatesIcon/>,
			name:'Weather insights',
			description:'Access the latest weather readings including extra values such as rain for an unlimited amount of towers located anywhere in Australia.'
		},{
			icon: <AirIcon/>,
			name:'Hazardous forecast',
			description:'Access hazardous forecast for the next 24 hours for an unlimited amount of towers located anywhere in Australia.'
		},{
			icon: <PeopleAltIcon/>,
			name:'Collaboration',
			description:'Invite up to 20 team members to help you take data-driven actions when it matters the most.'
		},{
			icon: <InsightsIcon/>,
			name:'Historical charts',
			description:'Monitor weather readings at any period in the past with charts and data export.'
		}]
	}
}



