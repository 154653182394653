export const HOME_PATH = '/'
export const CONFIRM_CODE_PATH = '/confirmcode'
export const FORGOT_PWD_PATH = '/forgotpwd'
export const LOGIN_PATH = '/login'
export const NOTIFICATION_MESSAGE_PATH = '/notifications/message'
export const NOTIFICATION_PATH = '/notifications'
export const PRIVACY_POLICY_PATH = '/privacy-policy'
export const RESET_PWD_PATH = '/resetpwd'
export const SEARCH_PATH = '/search'
export const SIGNUP_PATH = '/signup'
export const TERMS_PATH = '/terms'
export const TERMS_PDF_PATH = '/assets/legal/20221212_terms.pdf'
// Settings
export const SETTINGS_PATH = '/settings'
export const NOTIFICATION_TYPES_PATH = '/settings/notifications'
export const SPRAY_LOG_PATH = '/settings/spraylog'
export const PROFILE_PATH = '/settings/profile'
// Settings - Plan
export const SETTINGS_PLAN_PATH = '/settings/plan'
export const SETTINGS_UPGRADE_PLAN_PATH = '/settings/plan/upgrade'
export const SETTINGS_DOWNGRADE_PLAN_PATH = '/settings/plan/downgrade'
export const SETTINGS_MANAGE_PLAN_PATH = '/settings/plan/manage'
// Settings - Team
export const SETTINGS_TEAM_PATH = '/settings/team'