function setCookie(name, value, days) {
	let expires = ""
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
		expires = "; expires=" + date.toUTCString()
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

function getCookie(name) {
	const nameEQ = name + "="
	const ca = document.cookie.split(';')
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
	}
	return null
}

export const is_plan_on = () => {
	const plan_on = getCookie('plan_on')
	if (plan_on == 'true')
		return true
	else
		return process.env.REACT_APP_PLAN_ON == 'true'
}
export const set_plan_on = v => {
	setCookie('plan_on', v ? true : false, 365)
}