import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import styled from 'styled-components'

const ONE_SEC_IN_MS = 1000
const ONE_MIN_IN_MS = 60*ONE_SEC_IN_MS
const ONE_HOUR_IN_MS = 60*ONE_MIN_IN_MS
const ONE_DAY_IN_MS = 24*ONE_HOUR_IN_MS

const MainDiv = styled.div`
	display:grid;
	grid-template-columns: 1fr 1fr;
	justify-content: flex-start;
	margin: 0px var(--date-side-gutter) var(--date-margin-bottom, 50px) var(--date-side-gutter);

	@keyframes refresh-icon-spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}

	& .tz-details {
		white-space: nowrap;
		overflow: hidden;
		font-style: italic;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}

	& .spinning-btn {
		position: absolute;
		top: -10px;
	}

	& .refresh-button {
		display:flex;
		justify-content: flex-end;
	}

	& .refresh-spinner {
		animation-name: refresh-icon-spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@media (max-width: 768px) {
		grid-template-columns: 7fr 10fr;
		margin: 0px calc(var(--date-side-gutter) - 40px) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
	}

	@media (max-width: 500px) {
		&.datacard {
			margin: 0px calc(var(--date-side-gutter) - 22px) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
		}
	}

	@media (max-width: 430px) {
		&.datacard {
			margin: 0px calc(var(--date-side-gutter)) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
		}
	}

	@media (max-width: 420px) {
		&.datacard {
			margin: 0px calc(var(--date-side-gutter) + 10px) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
		}
	}

	@media (max-width: 400px) {
		&.datacard {
			margin: 0px calc(var(--date-side-gutter) + 20px) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
		}
	}

	@media (max-width: 390px) {
		&.datacard {
			margin: 0px calc(var(--date-side-gutter) + 30px) var(--date-margin-bottom, 50px) calc(var(--date-side-gutter) - 40px);
		}
	}
`

/**
 * Converts an number of ellapsed milliseconds into a string
 * 
 * @param  {Number}	time	e.g., 3000
 * 
 * @return {String}	text	e.g., '3 seconds ago'
 */
const formatEllaspedTime = (time:number) => {
	if (time === null)
		return null

	const seconds = Math.floor(time/ONE_SEC_IN_MS)
	if (seconds < 60)
		return `${seconds < 0 ? 0 : seconds} sec. ago`
	else {
		const minutes = Math.floor(time/ONE_MIN_IN_MS)
		if (minutes < 60)
			return `${minutes} min. ago`
		else {
			const hours = Math.floor(time/ONE_HOUR_IN_MS)
			if (hours < 24)
				return `${hours} hours ago`
			else {
				const days = Math.floor(time/ONE_DAY_IN_MS)
				return `${days} days ago`
			}
		}
	}
}

const reformatEllapsedTime = (loading?:Boolean, time?:any) => {
	const t = typeof(time) == 'string' 
		? new Date(time).getTime()
		: time instanceof Date 
			? time.getTime()
			: time

	if (loading)
		return '...'
	else if (t === null || t! < 0)
		return 'unknown'
	else 
		return formatEllaspedTime(Date.now() - t!)
}

export default function DateInfo({ ...props }) {
	const { tz, loading, lastReadingTime, onRefresh, sideGutter, marginBottom, classes, is_offline } = props
	const style:any = { '--date-side-gutter':sideGutter||'50px', '--date-margin-bottom':marginBottom||'50px' }
	return (<MainDiv style={style} className={classes}>
		<Typography component={'div'} variant="body2" color="text.secondary" className="tz-details">
			<div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Tz: {(tz||'').split('/').slice(-1)[0]}</div>
		</Typography>
		<div className="refresh-button" style={{ display:is_offline ? 'none' : undefined }}>
			<Typography variant="body2" color="text.secondary" className="tz-details">
				Last reading: {reformatEllapsedTime(loading, lastReadingTime)}
			</Typography>
			<div style={{ position: 'relative', width:'40px' }}>
				<IconButton onClick={onRefresh} title="refresh" color="primary" className="spinning-btn">
					<RefreshIcon className={loading ? 'refresh-spinner' : ''}/>
				</IconButton>
			</div>
		</div>
	</MainDiv>)
}




