import { useTheme } from '@mui/material/styles'
import { IonContent, IonPage } from '@ionic/react'
import Typography from '@mui/material/Typography'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import styled from 'styled-components'
import { ActionHeader } from './Header'

// padding:20px 26px 40px 26px;
const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	& > p,
	& > div,
	& > label {
		margin-bottom: 15px;
	}

	& .invite-input > div {
		height: 184px;
	}

	& .it-toolbar {
		display: grid;
		grid-template-rows: 60px;
		grid-template-columns: 100px 1fr 100px;
	}

	& .upgrade {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 6px;
		margin-right:6px;

		& p {
			display: flex;
			justify-content: center;
		}

		& .upgrade-btn {
			cursor: pointer;
			height: 28px;
			width: 96px;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--mu-primary-dark);
			background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			color: white;
			border-radius: 30px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:20px;
				margin-right: 3px;
			}
		}

		&.muted {
			& .upgrade-btn {
				background: #e2ebf3;
				color:var(--mu-primary-dark);
				& svg {
					font-size:16px;
				}
			}
		}

		&.get-pro {
			padding-top: 0px;
			& .upgrade-btn {
				width: 132px;
			}
		}
	}
`

const PlanButton = ({ ...props }) => {
	return (<div className={"upgrade" + (props.muted ? ' muted' : '')}>
		<div className="upgrade-btn" onClick={props.upgrade}>
			<div className="upgrade-text">
				{props.icon}
				{props.label}
			</div>
		</div>
	</div>)
}

const UpgradePopup = ({ ...props }:any) => {
	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}

	const on_cancel = props.onCancel || (() => null)

	const upgrade = () => {
		if (props.on_upgrade)
			props.on_upgrade()
	}

	return (
	<IonPage {...props}>
		<ActionHeader actionName="Upgrade" onCancel={on_cancel} onConfirm={upgrade}/>
		<IonContent className="ion-padding">
			<MasterDiv>
				<div className="upgrade" style={css_vars}>
					<Typography sx={{ marginBottom:'40px' }}>
						{props.message || 'Unlock this feature with a paid account.'}
					</Typography>
					<PlanButton  
						upgrade={upgrade}
						icon={<UpgradeIcon/>}
						label="Upgrade"/>
				</div>
			</MasterDiv>
		</IonContent>
	</IonPage>)
}

export default UpgradePopup