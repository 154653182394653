import { IonContent, IonPage } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { LOGIN_PATH } from '../../../path'
import { TitleHeader } from '../../../components/Header'

const MasterDiv = styled.div`
	padding:20px 26px 40px 26px;
`

const Plan = () => {
	const head = <Helmet>
		<title>Wand - Plan</title>
	</Helmet>

	const history = useHistory()
	const redirect = false
	
	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Downgrade" onBack={() => history.goBack()} />
				<IonContent fullscreen>
					<MasterDiv>
						
					</MasterDiv>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Plan