import { IonContent, IonPage, useIonToast } from '@ionic/react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { gql, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import { LOGIN_PATH, SETTINGS_UPGRADE_PLAN_PATH, SETTINGS_PATH, SETTINGS_PLAN_PATH } from '../../../path'
import { TitleHeader } from '../../../components/Header'
import { DescriptionBox, is_upgradable, is_paid } from '../Component'
import Toast from '../../../utils/Toast'
import { useUser } from '../../../data/user'
import { useTowers } from '../../../data/tower'
import { useMembers } from '../../../data/team'

const MasterDiv = styled.div`
	padding:20px 26px 40px 26px;

	& .title-header {
		display: grid;
		grid-template-columns: auto 140px;
		& .top-title {
			font-weight:bold;
			text-transform:capitalize;
		}
	}

	& .settings-btn {
		display:flex;
	}

	& .expiry-dates {
		padding: 4px 0px;
	}

	& .upgrade {
		grid-area: upgrade;
		display: flex;
		justify-content: start;
		padding-top: 6px;
		margin-right:6px;

		& .upgrade-btn {
			cursor: pointer;
			height: 28px;
			width: 96px;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--mu-primary-dark);
			background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
			color: white;
			border-radius: 30px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:20px;
				margin-right: 3px;
			}
		}

		&.muted {
			& .upgrade-btn {
				background: #e2ebf3;
				color:var(--mu-primary-dark);
				& svg {
					font-size:16px;
				}
			}
		}

		&.get-pro {
			padding-top: 0px;
			& .upgrade-btn {
				width: 132px;
			}
		}
	}

	& .upgrade-to-next-tier {
		background-color: #e2ebf3;
		height: auto;
		border-radius: 12px;
		margin-top: 18px;
		padding:12px;
		display:grid;
		grid-template-rows: 42px auto;
		grid-template-columns: 1fr 140px;
		grid-template-areas:
			'title button'
			'text  text';

		& .title {
			grid-area:title;
		}
		& .button {
			grid-area:button;
		}
		& .text {
			grid-area:text;
		}
	}
`

const SET_AUTO_RENEW = gql`
mutation set_auto_renew($auto_renew: Boolean) {
	user_update(plan_auto_renew: $auto_renew) {
		data{
			id
			first_name
			last_name
			email
			default_tower_id
			metadata
			phone {
				code
				number
			}
			organizations {
				plan
				plan_purchase_date
				plan_expiry_date
				plan_auto_renew
				go_weather_credit
			}
		}
	}
}`

const PlanButton = ({ ...props }) => {
	const history = useHistory()
	return (<div className={"upgrade" + (props.muted ? ' muted' : '')}>
		<div className="upgrade-btn" onClick={() => history.push(props.link)}>
			<div className="upgrade-text">
				{props.icon}
				{props.label}
			</div>
		</div>
	</div>)
}

const Plan = () => {
	const head = <Helmet>
		<title>Wand - Plan</title>
	</Helmet>

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)

	// Example: Get a specific search parameter
	const upgraded = searchParams.get('upgraded')
	if (upgraded)
		window.location.href = SETTINGS_PLAN_PATH

	const user_op = useUser()
	const tower_op = useTowers()
	const members_op = useMembers()
	const [update_auto_renew] = useMutation(SET_AUTO_RENEW)

	const toast = new Toast(...useIonToast(), { duration:5000 })

	const towers_used = (tower_op?.data?.towers||[]).reduce((acc:any,t:any) => acc + (t?.permissions||[]).length,0)
	const members_invited = (members_op?.data?.members||[]).length

	// const [plan, set_plan] = useState({})
	const history = useHistory()
	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}
	const redirect = false

	// store.set('plan', 'free', true)
	// store.get('plan', plan_name => {
	// 	if (plan_name && plan.name != plan_name)
	// 		set_plan(get_plan(plan_name))
	// })

	// console.log({
	// 	plan,
	// 	data: user_op.data
	// })

	const set_auto_renew = (auto_renew:any) => {
		update_auto_renew({
			variables:{ auto_renew },
			onCompleted:(data:any) => {
				const ae = (data?.user_update?.data?.organizations||[])[0]?.plan_auto_renew
				toast.show(`Plan auto-renewal successfully turned ${ae ? 'on' : 'off'}`)
			},
			onError: (err:any) => {
				console.error(err)
				toast.show('Oops, an error happened on our end. Please try again later.', { error:true, closeText:'close' })
			}
		})
	}
	
	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Your Plan" onBack={() => history.push(SETTINGS_PATH)} />
				<IonContent fullscreen>
					<div style={css_vars}>{user_op.loading ? null :
						<MasterDiv>
							<div className="title-header">
								<Typography variant="h5" component="h2" className="top-title">
									{user_op.data?.plan?.name}
								</Typography>{!is_paid(user_op.data?.plan?.name) ? null :
								<FormControlLabel
									value="start"
										control={<Switch 
										color="primary" 
										defaultChecked={user_op.data?.plan_auto_renew}
										onChange={(e:any) => set_auto_renew(e.target.checked)} />}
									label={<Typography variant="body2" color="primary">Auto-renew</Typography>}
									labelPlacement="start"
									className="h-icon"
								/>}
							</div>{is_paid(user_op.data?.plan?.name) ?
							<div className="expiry-dates">
								<Typography variant="caption" color="text.secondary" className="text">
									Yearly - Next payment on {user_op.data?.plan_expiry_date_label}
								</Typography>
							</div> : null}
							<div className="settings-btn">{is_upgradable(user_op.data?.plan?.name) ?
								<PlanButton  
									link={SETTINGS_UPGRADE_PLAN_PATH}
									icon={<UpgradeIcon/>}
									label="Upgrade"/> : null}
									{/*{is_paid(user_op.data?.plan?.name) ? 
								<PlanButton  
									link={SETTINGS_MANAGE_PLAN_PATH}
									icon={<SettingsIcon/>}
									muted={true}
									label="Manage"/> : null}*/}
							</div>
							<DescriptionBox
								title="Your limits"
								items={user_op.data?.plan?.limits}
								members={members_invited}
								towers={towers_used}
								go_weather_credit={user_op.data?.go_weather_credit}
							/>{user_op.data?.plan?.upgrade_ad ?
							<div className="upgrade-to-next-tier">
								<Typography variant="h6" component="h2" className="title" sx={{ fontSize:'1rem', paddingBottom:'8px' }}>
									{user_op.data?.plan?.upgrade_ad?.name}
								</Typography>
								<div className="upgrade button get-pro">
									<div className="upgrade-btn" onClick={() => history.push(SETTINGS_UPGRADE_PLAN_PATH)}>
										<div className="upgrade-text">
											{user_op.data?.plan.upgrade_ad.cta}
										</div>
									</div>
								</div>
								<Typography variant="caption" color="text.secondary" className="text">
									{user_op.data?.plan?.upgrade_ad?.message}
								</Typography>
							</div> : null }
							<DescriptionBox
								title="Your benefits"
								items={user_op.data?.plan?.benefits}
								no_icon_color={true}
							/>
						</MasterDiv>}
					</div>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Plan