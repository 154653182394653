import { useEffect, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { RED, get_pin_color } from '../utils/googleMap'

const TOWERS_AREA_CENTROID = { // Grosmont, Queensland coord (centroid as of Nov 2022)
	lat: -25.94158076002502, 
	lon: 149.90733836291855
}

const API_KEY = 'AIzaSyDj3zy0jSN9Mo23aXuRNINs62rplAXkDbc'
const loader = new Loader({
	apiKey: API_KEY,
	version: 'weekly',
	libraries: ['places']
})
const asyncGoogle = loader.load()

/**
 * 
 * @param	{Object}	google		
 * @param	{Object}	options
 * @param	{String}		.color			Default '#e04633' (red)
 * @param	{Number}		.border			Default 0
 * @param	{String}		.borderColor	Default '#e04633' (red)
 * @param	{Boolean}		.hole			Default false.
 * @param	{Number}		.scale			Default 2.
 * 
 * @return	{Icon}		icon
 */
const createMarkerIcon = (google:any, options?:any) => {
	const { color, borderColor, hole, border, scale } = options||{}
	// Pick your pin (hole or no hole)
	const pinSVGHole = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z'
	const labelOriginHole = new google.maps.Point(12,15)
	const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z'
	const labelOriginFilled =  new google.maps.Point(12,9)


	const markerIcon:any = {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
		path: hole ? pinSVGHole : pinSVGFilled,
		anchor: new google.maps.Point(12,17),
		fillOpacity: 1,
		fillColor: color || RED,
		strokeWeight: border || 0,
		strokeColor: borderColor || RED,
		scale: scale || 2,
		labelOrigin: hole ? labelOriginHole : labelOriginFilled
	}

	return markerIcon
}

const get_tower_status = (latest_reading:any, disabled?:boolean) => {
	if (disabled)
		return 'disabled'
	else if (latest_reading?.hazard !== null)
		return latest_reading?.hazardous_status
	else
		return 'absent'
}

export default function GoogleMap({ ...props }) {
	const { center, zoom=5, towers, onTowerClick, currentLoc } = props
	const { lat, lon } = currentLoc || {}

	const node = useRef<any>()
	const [map, setMap] = useState<any | null>(null)
	const [towersSignature, setTowersSignature] = useState('')
	const [markers, setMarkers] = useState<any>([])

	useEffect(() => {
		const load = async () => {
			if (node?.current) {
				const _towersSignature = (towers||[]).map((t:any) => `${t?.id||0}${t?.disabled||false}`).join('_')
				if (_towersSignature != towersSignature) {
					let _map:any = null
					const google = await asyncGoogle
					// Create Map if it was not created yet
					if (!map) {
						_map = new google.maps.Map(node.current, {
							center: {
								lat: center?.lat || TOWERS_AREA_CENTROID.lat, 
								lng: center?.lon || TOWERS_AREA_CENTROID.lon
							},
							zoom,
							disableDefaultUI: true
						})
						setMap(_map)
					} else
						_map = map

					// Add current location
					if (lon !== undefined && lon !== null && lat !== undefined && lat !== null) {
						new google.maps.Marker({
							position: { lat, lng:lon },
							map: _map,
							icon: createMarkerIcon(google, {
								color: '#3389e0',
								borderColor: '#05509b',
								border: 2
							}),								
							label: { 
								text: 'YOU', 
								color: 'white', 
								fontSize: '11px' 
							},
							title: 'Your current location'
						})
					}

					// Add tower pins
					setTowersSignature(_towersSignature)
					// Remove all pre-existing markers
					if (markers && markers.length) {
						for(let i=0;i<markers.length;i++) {
							let mark = markers[i]
							mark.setMap(null)
							mark = null
						}
					}

					const _markers = (towers||[]).map((tower:any, idx:number) => {
						const { coord, name:title } = tower||{}
						const { lat, long:lng } = coord||{}
						if (lat !== undefined && lat !== null && lng !== undefined && lng !== null) {

							const marker = new google.maps.Marker({
								position: { lat, lng },
								map: _map,			
								icon: createMarkerIcon(google, {
									color: get_pin_color(get_tower_status(tower?.latest_reading, tower.disabled)),
									borderColor: get_pin_color(get_tower_status(tower?.latest_reading, tower.disabled), true),
									border: 1
								}),					
								label: { 
									text: tower.map_label, 
									color: 'white', 
									fontSize: '11px' 
								},
								title 
							})

							if (onTowerClick)
								marker.addListener('click', () => {
									onTowerClick(tower)
								})

							return marker
						} else
							return null
					}).filter((x:any) => x)
					setMarkers(_markers)
				}
			}
		}
		load()
	},[center, zoom, towers, onTowerClick, node, map, towersSignature, lat, lon, markers])

	return <div ref={node} style={{ width:'100%', height:'100%' }}></div>
}






