import styled from 'styled-components'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Typography from '@mui/material/Typography'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import { GUTTER, CONTAINER_PAD, SectionTitle, is_upgradable } from './Component'
import { SETTINGS_PLAN_PATH, SETTINGS_UPGRADE_PLAN_PATH } from '../../path'
import { is_plan_on } from '../../utils/global'

const HeaderDiv = styled.div`
	padding-bottom: ${CONTAINER_PAD};
	padding-left: ${GUTTER};
	padding-right: ${GUTTER};
	padding-top: 30px;
	display: grid;
	grid-template-rows: 26px 26px auto;
	grid-template-columns: 3fr 2fr;
	grid-template-areas:
		'medalion upgrade'
		'medalion plan'
		'name     plan';

	& .medalion {
		grid-area:medalion;
	}

	& .name {
		grid-area:name;
		padding: 10px 0px;
	}

	& .upgrade {
		grid-area: upgrade;
		display: flex;
		justify-content: end;

		& .upgrade-btn {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			background-color: black;
			color: white;
			border-radius: 30px;
			width: 76px;
			font-size: 12px;
			font-weight: 500;

			& .upgrade-text {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: -5px;
			}

			& svg {
				font-size:16px;
			}
		}
	}

	@media (max-width: 768px) {
		grid-template-rows: 52px auto 65px;
		grid-template-columns: 3fr 3fr 2fr;
		grid-template-areas:
			'medalion medalion upgrade'
			'name     name     upgrade'
			'plan     plan     plan';
		}
`

const PlanTileDiv = styled.div`
	cursor: pointer;
	grid-area:plan;
	border-radius: 12px;
	background: var(--mu-primary-dark);  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	width: 100%;
	padding: 10px;
	color: white;
	display: grid;
	grid-template-rows: 32px 20px;
	grid-template-columns: 1fr;
	grid-template-areas:
		'plan'
		'yp';

	& .yp {
		grid-area:yp;
		font-size:12px;
	}

	& .plan {
		grid-area:plan;
	}

	@media (max-width: 768px) {
		margin-left: 0px;
		width: 100%;
	}
`

const PlanTile = ({ ...props }) => {
	const history = useHistory()
	const { plan='Free' } = props

	return (<PlanTileDiv onClick={() => history.push(SETTINGS_PLAN_PATH)}>
		<SectionTitle 
			class_name="plan"
			color="white"
			left_gutter="0px"
			right_gutter="0px"
			padding_bottom="0px"
			padding_top="0px">
			{`${plan}`.replace(/^./, x => x.toUpperCase())}
		</SectionTitle>
		<div className="yp">Your plan</div>
	</PlanTileDiv>)
}

const Header = ({ ...props }) => {
	const { fullname, plan } = props
	
	const plan_on = is_plan_on()

	const history = useHistory()

	return (
		<HeaderDiv>
			<Box className="medalion" sx={{ display:'flex', alignItems:'flex-start', justifyContent:'flex-start' }}>
				<AccountCircleIcon sx={{ fontSize:'54px' }}/>
			</Box>
			<Box className="name">
				<Typography variant="h5" component="h2" sx={{ fontWeight:'bold' }}>{fullname}</Typography>
			</Box>{plan_on && is_upgradable(plan) ?
			<div className="upgrade">
				<div className="upgrade-btn" onClick={() => history.push(SETTINGS_UPGRADE_PLAN_PATH)}>
					<div className="upgrade-text">
						<UpgradeIcon/>
						Upgrade
					</div>
				</div>
			</div> : null}{!plan_on ? null :
			<PlanTile plan={plan}/>}
		</HeaderDiv>
	)
}

export default Header