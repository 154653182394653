import { gql, useQuery } from '@apollo/client'


const GET_MEMBERS_GQL = () => gql`
query get_members {
	members {
		id
		user_id
		first_name
		last_name
		email
		create_date
		roles
	}
}`

export const useMembers = options => {
	const { onCompleted, onError } = options || {}

	const queryOption = {}
	if (onCompleted)
		queryOption.onCompleted = onCompleted
	if (onError)
		queryOption.onError = onError

	const query = useQuery(GET_MEMBERS_GQL(), queryOption)

	return query
}





