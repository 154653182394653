import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { gql, useMutation } from '@apollo/client'
import { IonContent, IonPage } from '@ionic/react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import ProgressBar from './ProgressBar'
import { ActionHeader } from './Header'

// padding:20px 26px 40px 26px;
const MasterDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;

	& > p,
	& > div,
	& > label {
		margin-bottom: 15px;
	}

	& .upgrade-now {
		cursor: pointer;
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: 600;
		color: var(--mu-primary-dark);
	}

	& .towers {
		display:grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'error      error'
			'unselected selected';

		& .error {
			grid-area:error;
			margin-bottom: 20px;
			color:red;
			font-style:italic;
		}
		
		& .unselected {
			grid-area:unselected;
		}

		& .selected {
			grid-area:selected;
		}
	}
`

const UPDATE_TOWERS = gql`
mutation towers_per_organization_update($tower_ids:[ID]!) {
	towers_per_organization_update(tower_ids:$tower_ids) {
		id
		name
		coord {
			lat
			long
			tz
		}
		permissions
		devices{
			serial_no
			status
		}
	}
}`

const FormSelectTowers = ({ ...props }:any) => {
	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}
	const [disabled_ids, set_disabled_ids] = useState((props.towers||[]).filter((t:any) => t.disabled).map((t:any) => t.id))
	const [err_msg, set_err_msg] = useState(false)

	const [update_towers, update_towers_op] = useMutation(UPDATE_TOWERS)

	const on_save = () => {
		if (props.towers && props.towers.length) {
			const tower_ids = props.towers.filter((t:any) => disabled_ids.indexOf(t.id) < 0).map((t:any) => t.id)
			update_towers({
				variables:{ tower_ids },
				onCompleted:props.on_save,
				onError:props.on_error
			})
		}
	}

	const select_tower = (id:any) => {
		const selected_towers_count = (props.towers||[]).length - disabled_ids.length
		if (selected_towers_count < limit) {
			set_err_msg(selected_towers_count + 1 == limit)
			set_disabled_ids(disabled_ids.filter((_id:any) => _id != id))
		} else
			set_err_msg(true)
	}

	const unselect_tower = (id:any) => {
		set_err_msg(false)
		set_disabled_ids([...disabled_ids, id])
	}

	const plan = (props.towers||[])[0]?.plan
	const limit = plan == 'professional' ? 10 : 10000000000

	return (
	<IonPage {...props}>
		{update_towers_op.loading && <ProgressBar/>}
		<ActionHeader actionName="Save" onCancel={props.on_cancel} onConfirm={on_save}/>
		<IonContent className="ion-padding">
			<MasterDiv>
				<div className="towers">{!err_msg ? null :
					<div className="error">
						You've reached the maximum amount of selected towers allowed in your plan. 
						To increase that quota, <span className="upgrade-now" style={css_vars} onClick={props.on_upgrade}>Upgrade now!</span> 
					</div>}
					<div className="unselected">
						<Typography sx={{ fontSize:'18px', fontWeight:500, marginBottom:'20px' }}>
							Unselected ({disabled_ids.length})
						</Typography>
						<FormGroup>{(props.towers||[]).filter((t:any) => disabled_ids.some((id:any) => id == t.id)).map((tower:any) => { return(
							<FormControlLabel key={tower.id} control={<Checkbox 
								disabled={(props.towers||[]).length - disabled_ids.length >= limit}
								onChange={() => select_tower(tower.id)}
								/>} label={tower.name}/>)})}
						</FormGroup>
					</div>
					<div className="selected">
						<Typography sx={{ fontSize:'18px', fontWeight:500, marginBottom:'20px' }}>
							Selected ({(props.towers||[]).length - disabled_ids.length})
						</Typography>
						<FormGroup>{(props.towers||[]).filter((t:any) => !disabled_ids.some((id:any) => id == t.id)).map((tower:any) => { return(
							<FormControlLabel key={tower.id} control={<Checkbox 
								defaultChecked 
								onChange={() => unselect_tower(tower.id)}
								/>} label={tower.name}/>)})}
						</FormGroup>
					</div>
				</div>
			</MasterDiv>
		</IonContent>
	</IonPage>)
}

export default FormSelectTowers