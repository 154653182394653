import { useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import { LOGIN_PATH, TERMS_PDF_PATH, SETTINGS_PLAN_PATH } from '../../../path'
import { TitleHeader } from '../../../components/Header'
import { store, get_plan } from '../dev_dummy_store'
import { useUser } from '../../../data/user'

const MasterDiv = styled.div`
	padding:20px 26px 40px 26px;

	& .downgrade-btn {
		cursor:pointer;
		padding:6px 6px 6px 14px;
		background-color:#efefef;
		border-radius:12px;
		margin-bottom:10px;

		color: var(--mu-error-light);
		& .downgrade {
			font-size: 1rem;
			padding-bottom: 0px;
		}
	}

	& .footer {
		& a {
			margin-left: 1px;
		}
	}
`

const Plan = () => {
	const head = <Helmet>
		<title>Wand - Manage</title>
	</Helmet>

	const [plan, set_plan] = useState({})
	const theme = useTheme()
	const css_vars:any = {
		'--mu-error-light': theme.palette.error.light,
		'--mu-error-dark': theme.palette.error.dark,
		'--mu-error-main': theme.palette.error.main
	}

	useUser({
		onCompleted(data:any) {
			set_plan(get_plan(data?.plan || 'free'))
		}
	})

	const history = useHistory()
	const redirect = false

	const upgrade = selected_plan => {
		store.set('plan', selected_plan)
		history.push(SETTINGS_PLAN_PATH)
	}
	
	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Manage Plan" onBack={() => history.goBack()} />
				<IonContent fullscreen>
					<div style={css_vars}>
						<MasterDiv>{(plan?.lower_plans||[]).map((p,key) => (
							<div className="downgrade-btn" onClick={() => upgrade(p)}>
								<Typography variant="h6" component="p" className="downgrade">
									Downgrade to {`${p}`.replace(/^./, x => x.toUpperCase())}
								</Typography>
							</div>))}
						<div className="footer">
							<Typography variant="caption" component="p" className="text" color="text.secondary" >
								Read more about <a target="_blank" rel="noreferrer" href={TERMS_PDF_PATH}>cancelling your plan</a> and fees that may apply when you downgrade or switch plans.
							</Typography>
						</div>
						</MasterDiv>
					</div>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Plan