import { useState, useRef } from 'react'
import { gql, useMutation } from '@apollo/client'
import { IonContent, IonPage, useIonModal, useIonToast, IonModal } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { LOGIN_PATH, SETTINGS_PATH, SETTINGS_UPGRADE_PLAN_PATH } from '../../../path'
import { TitleWithActionHeader } from '../../../components/Header'
import FormInviteMember from '../../../components/FormInviteMember'
import SaveCancelButton from '../../../components/SaveCancelButton'
import ProgressBar from '../../../components/ProgressBar'
import Toast from '../../../utils/Toast'
import { useUser } from '../../../data/user'
import { useMembers } from '../../../data/team'

const MasterDiv = styled.div`
	padding:0px 0px 40px 0px;


	& .nomembers {
		padding: 26px;
		display:flex;
		justify-content:center;
	}

	& .list {
		display:flex;
		flex-direction: column;
		& .member {
			display:grid;
			grid-template-columns: 2fr 5fr 3fr;
			height: 45px;
			align-items: center;
			border-bottom: 1px solid #e6e6e6;

			&.items {
				&:hover {
					background-color:#f8f8f8;
				}

				& .item {
					cursor:pointer;
				}
			}

			& .item {
				white-space:nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-left:20px;
			}

			&.header {
				background-color:#dfdfdf;
				& .item {
					font-weight:bold;
					text-transform:uppercase;
				}
			}

			@media (max-width: 768px) {
				grid-template-columns: 1fr 2fr;
				& .role {
					display: none;
				}

				& .item {
					font-size:14px;
				}
			}
		}
	}
`

const UPDATE_MEMBER = gql`
mutation update_member($id:ID!, $first_name:String, $last_name:String, $roles:[MemberRoleEnum]) {
	member_update(
		id:$id
		first_name:$first_name
		last_name:$last_name
		roles:$roles) {
			id
			user_id
			first_name
			last_name
			email
			create_date
			roles
	}
}`

const DELETE_MEMBER = gql`
mutation delete_member($id:ID!) {
	member_delete(id:$id) {
		message
	}
}`

const format_role = (role:String) => {
	if (role)
		return role
			.replace(/_./g, x => x.toUpperCase().replace('_',' '))
			.replace(/^./, x => x.toUpperCase())
			.replace(' And ', ' and ')
	else 
		return '--'
}

const get_expected_modal_breakpoint = (h?:number) => {
	if (!h)
		return 0
	else if (h < 600)
		return 1
	else 
		return 0.6
}

const Form = ({ title, description, onCancel, onSave, children, saving, on_delete, delete_label="Delete" }: { title?:any, description?:any, onCancel:Function, onSave:Function, on_delete:Function, children?:any, saving?:boolean, delete_label:string }) => {
	return (
		<Box sx={{ padding:'26px', width:'100%' }}>
			<Box className="field-form">
				<Box sx={{ paddingBottom:description ? '0px' : '10px', display:'flex', marginTop:'-15px' }}>
					<Typography variant="h6" sx={{ width:'50%' }}>{title||''}</Typography>
					<Box sx={{ width:'50%', display:'flex', justifyContent:'flex-end' }}>
						<IconButton aria-label="close" sx={{ marginRight:'-15px' }} onClick={() => onCancel()}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
				{description && <Typography variant="body2" sx={{ marginBottom:'12px' }}>{description}</Typography>}
				{children}
			</Box>
			<SaveCancelButton onCancel={onCancel} saving={saving} onSave={onSave} sx={{ paddingTop:'10px' }} />
			<Button 
				variant="outlined" 
				color="error"
				onClick={() => on_delete()} 
				sx={{ width:'100%', textTransform: 'none', marginTop:'10px' }}
				size="large">
				{delete_label}
			</Button>
		</Box>
	)
}

const FormMember = ({ member, on_cancel, on_save, on_delete, saving }: { member:any, on_cancel:Function, on_save:Function, on_delete:Function, saving?:boolean }) => {
	const [first_name, set_first_name] = useState(member.first_name||'')
	const [last_name, set_last_name] = useState(member.last_name||'')
	const [role, set_role] = useState(member.roles[0]||'')
	const [confirmed_deletion, set_confirmed_deletion] = useState(false)

	const onsave = () => {
		if (on_save)
			on_save({
				...member,
				first_name,
				last_name,
				roles:[role]
			})
	}

	const ondelete = () => {
		if (confirmed_deletion)
			on_delete(member.id)
		else
			set_confirmed_deletion(true)
	}

	return (
		<Form 
			title="Edit member" 
			onCancel={on_cancel} 
			onSave={onsave} 
			on_delete={ondelete}
			delete_label={confirmed_deletion ? 'Confirm deletion' : 'Delete'}
			saving={saving}>
			<TextField
				label="Email"
				defaultValue={member.email||''}
				disabled={true}
			/>
			<FormControl fullWidth>
				<InputLabel id="role">Role *</InputLabel>
				<Select
					id="role"
					required
					label="Role"
					value={role}
					onChange={e => set_role(e.target.value)}
					>
					<MenuItem value={'read'}>Read</MenuItem>
					<MenuItem value={'read_and_spray'}>Read and Spray</MenuItem>
				</Select>
			</FormControl>
			<TextField
				label="First name"
				defaultValue={member.first_name||''}
				onChange={(e:any) => set_first_name(e.target.value)}
				placeholder="Member's first name"
			/>
			<TextField
				label="Last name"
				defaultValue={member.last_name||''}
				onChange={(e:any) => set_last_name(e.target.value)}
				placeholder="Member's last name"
			/>
		</Form>
	)
}

const get_fullname = (member:any) => [member?.first_name,member?.last_name].filter((x:any) => x).join(' ')

const Members = () => {
	const head = <Helmet>
		<title>Wand - Team Members</title>
	</Helmet>

	useUser() // Preload user details
	const members_op = useMembers()
	const history = useHistory()
	const redirect = false

	const [update_member] = useMutation(UPDATE_MEMBER)
	const [delete_member] = useMutation(DELETE_MEMBER)

	let loading = members_op.loading

	const [selected_member, set_selected_member] = useState({})
	const modal:any = useRef<HTMLIonModalElement>(null)

	const toast = new Toast(...useIonToast(), { duration:5000 })
	const [show_invite_model, dismiss_invite_model] = useIonModal(FormInviteMember, {
		history,
		onCancel: () => {
			dismiss_invite_model()
		},
		onConfirm: () => {
			toast.show('Invitation sent')
			dismiss_invite_model()
			members_op.refetch()
		},
		on_upgrade:() => {
			dismiss_invite_model()
			history.push(SETTINGS_UPGRADE_PLAN_PATH)
		},
		on_error: (err_msg:any) => {
			dismiss_invite_model()
			toast.show(err_msg||'Oops, an error happened on our end. Please try again later.', { error:true, closeText:'close' })
		}
	})

	const open_editor = (member:any) => {
		set_selected_member(member)
		const bp = get_expected_modal_breakpoint(window.innerHeight||0)
		const m:any = modal.current || {}
		m.present()
		if (bp && bp != 0.6)
			setTimeout(() => {
				m.setCurrentBreakpoint(bp)
			}, 400)
	}

	const hide_editor = () => {
		if (modal?.current?.dismiss)
			modal.current.dismiss()
	}

	const save_member = (member:any) => {
		const variables = {
			id: member.id,
			first_name: member.first_name,
			last_name: member.last_name,
			roles: member.roles
		}
		update_member({
			variables,
			onCompleted() {
				toast.show('Member successfully saved')
				modal.current.dismiss()
			},
			onError(error:any) {
				console.log(error)
				toast.show('Oops, something went wrong. Please try again later.', { error:true, closeText:'close' })
			}
		})
	}

	const on_delete = (id:any) => {
		const variables = { id }
		delete_member({
			variables,
			onCompleted() {
				toast.show('Member successfully deleted')
				modal.current.dismiss()
				members_op.refetch()
			},
			onError(error:any) {
				console.log(error)
				toast.show('Oops, something went wrong. Please try again later.', { error:true, closeText:'close' })
			}
		})
	}

	const open_invite_modal = e => {
		e.preventDefault()
		show_invite_model()
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> : (
		<div>
			<IonPage>{loading && 
				<ProgressBar/>}
				{head}
				<TitleWithActionHeader 
					title="Team Members" 
					actionName="Invite"
					onConfirm={show_invite_model}
					onBack={() => history.push(SETTINGS_PATH)} />
				<IonContent fullscreen>
					<MasterDiv>
						<div className="list">
							<div className="member header">
								<div className="item name">Name</div>
								<div className="item email">Email</div>
								<div className="item role">Role</div>
							</div>{!(members_op?.data?.members||[]).length ? 
							<div className="nomembers">
								<Typography variant="caption" component="p" className="text" color="text.secondary" >
									You have no members yet. <a href="" onClick={open_invite_modal}>Invite your 1st member now</a>.
								</Typography>
							</div> : members_op.data.members.map((member:any, key:any) => (
							<div className="member items" key={key} onClick={() => open_editor(member)}>
								<div className="item name">{get_fullname(member)||'--'}</div>
								<div className="item email">{member.email||'--'}</div>
								<div className="item role">{format_role((member.roles||[])[0])}</div>
							</div>))}
						</div>
					</MasterDiv>
				</IonContent>
			</IonPage>
			<IonModal 
				ref={modal} 
				breakpoints={[0, 0.6, 0.8, 0.9, 1]} 
				initialBreakpoint={0.8} 
				onDidDismiss={hide_editor}
				className="profile" 
				handle={false}>
					<FormMember
						member={selected_member}
						on_cancel={hide_editor}
						on_delete={on_delete}
						on_save={save_member}/>
			</IonModal>
		</div>
	)
}

export default Members