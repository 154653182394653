import { useRef, useEffect } from 'react'
import * as echarts from 'echarts'
import styled from 'styled-components'

const BLUE = '#1565c0'
const RED = '#f44336'
const LARGE_SCREEN = 920

const GraphContainer = styled.div`
	& .graph {
		width: 100vw;
		height: 350px; 
	}
`

export default function HistoryGraph({ ...props }) {
	const { x, y, warningZone, normalZone, showLegend, color=BLUE, hideYValues, tooltipOff } = props
	const { from, to, label:warninLabel, color:warningColor=RED, threshold, hideLine } = warningZone || {}
	const node = useRef(null)

	useEffect(() => {
		const el:any = node.current
		const chart = echarts.init(el)

		const series:any = [{
			name: y?.label,
			data: y?.data,
			type: 'line',
			smooth: true,
			markLine: from || to ? {
				// symbol: 'none',
				lineStyle: {
					color: warningColor
				},
				data: hideLine ? null : [{
					yAxis: threshold||0
				}]
			} : null
		}]

		const pieces = [{
			gte: from||0,
			lte: to||0,
			label: warninLabel,
			smallLabel: warningZone?.smallScreenLabel || warninLabel,
			color: warningColor
		}]
		if (normalZone && (normalZone?.from !== undefined || normalZone?.to !== undefined)) {
			const piece:any = {
				color: BLUE,
				label: normalZone?.label,
				smallLabel: normalZone?.smallScreenLabel || normalZone?.label,
			}
			if (normalZone?.from !== undefined)
				piece.gt = normalZone?.from
			if (normalZone?.to !== undefined)
				piece.lt = normalZone?.to
			pieces.push(piece)
		}

		const visualMap = from || to ? {
			top: 20,
			right: showLegend ? 100 : -100,
			pieces,
			outOfRange: {
				color
			}
		} : undefined
		const smallVisualMap = visualMap ? {
			...visualMap,
			right: 30,
			pieces: pieces.map(p => ({ ...p, label:p.smallLabel }))
		} : undefined

		chart.setOption({
			legend: {},
			tooltip: tooltipOff ? undefined : {
				trigger: 'axis',
				axisPointer: {
					type: 'cross'
				}
			},
			visualMap: window.innerWidth >= LARGE_SCREEN ? visualMap : smallVisualMap,
			xAxis: {
				data: x?.data
			},
			yAxis: {
				show: !hideYValues
			},
			dataZoom: [{
				type: 'slider',
				height: 30,
				start: 0,
				end: 100,
				bottom: 10
			}],
			series
		})

		const resizeChart = () => {
			if(chart != null && chart != undefined) {
				if (visualMap && showLegend)
					chart.setOption({
						visualMap: window.innerWidth >= LARGE_SCREEN ? visualMap : smallVisualMap
					})
				chart.resize()
			}
		}

		window.addEventListener('resize', resizeChart)

		return () => {
			// if (chart) {
			// 	window.removeEventListener('resize', resizeChart)
			// 	chart.dispose()
			// 	chart.clear()
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[x,y])
	

	return (<GraphContainer className="graph-container">
		{/*<Typography variant="h6" sx={{ fontWeight:'bold' }} className="h-title">{title}</Typography>*/}
		<div ref={node} className="graph">
		</div>	
	</GraphContainer>)
}






